@import "../../assets/scss/theme/theme1/theme.scss";

.user-management-details-option-container {
    display: flex;
    justify-content: flex-end;
}
.content-input {
    // color: #a5a5a5 !important;
    font-family: "Poppins";
    font-size: 12px !important;
    font-weight: 400 !important;
}
.content-mang-edit-btn-margin {
    margin-left: 20px;
}

.skill-level-label{
    color:  $theme-primary-color;
}
.content-container{
    .custom-card {
        // height: 500px;
        // .image-box-con{
        //     height: 400px;
        // }
    }

}

@media (max-width: 767px) {
    .content-modal-resopnsiveness {
        .custom-btn-lg {
            width: 100% !important;
        }
        .custom-btn-lg-secondary {
            margin-top: 5px;
        }
        .custom-btn.custom-btn-lg-secondary {
            min-width: 100%;
        }
    }
}

/* Extra Small Devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .user-management-details-option-container {
        display: flex;
        justify-content: space-between;
    }
}
